<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link href="https://oneposts.io" class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">ONEPOST</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >

          <b-overlay
            :show="loading"
            rounded="sm"
            spinner-variant="primary"
            variant="transparent"
          >
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
            Welcome to Onepost! 👋
            </b-card-title>
            <b-card-text class="mb-2">
              Please sign-in to your account and start the adventure
            </b-card-text>

            <b-alert
              show
              variant="danger"
              v-if="hasError"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="AlertCircleIcon"
                />
                <span class="ml-25">Please check email, password or email Verification</span>
              </div>
            </b-alert>

            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
              >
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      dir="ltr"
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <b-link :to="{name:'forgot-password'}">
                      <small>Forgot password ?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  @click="validationForm"
                >
                  login
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <span>New on our platform?</span>
              <b-link :to="{name:'register'}">
                <span>&nbsp;Create an account</span>
              </b-link>
            </b-card-text>

            <!-- divider -->
            <div class="divider my-2">
              <div class="divider-text">
                or
              </div>
            </div>

            <div class="auth-footer-btn d-flex justify-content-center">
              <b-button
                variant="facebook"
                href="javascript:void(0)"
              >
                <feather-icon icon="FacebookIcon" />
              </b-button>
              <b-button
                variant="twitter"
                href="javascript:void(0)"
              >
                <feather-icon icon="TwitterIcon" />
              </b-button>
              <b-button
                variant="google"
                href="javascript:void(0)"
              >
                <feather-icon icon="MailIcon" />
              </b-button>
              <b-button
                variant="github"
                href="javascript:void(0)"
              >
                <feather-icon icon="GithubIcon" />
              </b-button>
            </div>
          </b-overlay>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BAlert, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, 
  BImg, BForm, BButton, BOverlay
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BRow, BOverlay,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      hasError: false,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created(){
    if (this.$store.state.auth.isAuthenticated){
      this.$router.push('/channels')
    }
  },
  methods: {
    validationForm() {
      this.loading = true;
      this.hasError = false;
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          axios.defaults.headers.common['Authorization'] = ''
          localStorage.removeItem('token')

          const formData = {
              username: this.userEmail,
              password: this.password
          }
          axios.post('/login/', formData)
          .then(response => {
            this.loading = false;
            const token = response.data.token
            this.$store.commit('auth/setToken', token)
            axios.defaults.headers.common['Authorization'] = 'Token ' + token
            localStorage.setItem('token', token)

            this.$store.commit('auth/setUser',
              {
                'id': response.data.user.id,
                'email': response.data.user.email,
                'full_name': response.data.user.full_name,
              }
            )

            this.$store.commit('auth/setBrand', response.data.user.brands[0])

            localStorage.setItem('email', response.data.user.email)
            localStorage.setItem('fullname', response.data.user.full_name)
            localStorage.setItem('userid', response.data.user.id)
            localStorage.setItem('account_id', response.data.user.account.id)

            localStorage.setItem('brands_quota_monthly', response.data.user.account.brands_quota_monthly)
            localStorage.setItem('channels_quota_monthly', response.data.user.account.channels_quota_monthly)
            localStorage.setItem('comments_quota_monthly', response.data.user.account.comments_quota_monthly)
            localStorage.setItem('members_quota_monthly', response.data.user.account.members_quota_monthly)
            localStorage.setItem('posts_quota_monthly', response.data.user.account.posts_quota_monthly)

            localStorage.setItem('planid', response.data.user.account.plan.id)
            localStorage.setItem('planname', response.data.user.account.plan.name)

            this.$store.commit('auth/setPlan',
              {
                'id': response.data.user.account.plan.id,
                'name': response.data.user.account.plan.name,
              }
            )
            this.$router
              .push('/channels')
              .then(() => { this.$router.go() });
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
            this.hasError = true;
            if (error.response) {
              this.hasError = true;
            } else if (error.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error !',
                  text: 'Something Wrong, try again',
                  variant: 'danger',
                  icon: 'AlertCircleIcon'
                },
              })
            }
          })
        } else {
          this.loading = false;
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
